<div [loadingSpinner]="{ isLoading }" class="chartSection" style="display: flex">
    <div [id]="chartID" [style.width]="chartHeight"></div>
    <div class="chartLegend ml-4">
        <div class="legend">
            <div *ngFor="let chartRow of chartData">
                <i [style.background]="colorRange.get(chartRow.XValue)"></i> <strong>{{ chartRow.XValue }}</strong>
                <span class="legendEntryExtraInfo" *ngIf="chartRow.YValue > 0"> {{ chartRow.YValue | number: "1.0-1" }} acres ({{ chartRow.HoverValue | percent: "1.0-1" }})</span>
            </div>
        </div>
    </div>
</div>
