<section class="content-section">
    <form [formGroup]="formGroup">
        <div class="grid-12">
            <div class="g-col-3">
                <div class="filter-wrapper">
                    <div class="filter-header">
                        <h3><icon icon="Filter"></icon> Filters</h3>
                    </div>

                    <div class="filter-body">
                        <div class="filter-section">
                            <div class="filter-section-title" [expandCollapse]="filterBodyLocation" [startOpen]="true">
                                <h4><span class="number">1</span>Location</h4>
                                <icon icon="AngleDown"></icon>
                            </div>
                            <div class="filter-section-body" #filterBodyLocation>
                                <div class="dropdown-container">
                                    <ng-template #optionTemplate let-item="item" let-config="config">
                                        <div class="switcher">
                                            <div class="switcher-title">
                                                <span class="switcher-name">{{ item.EntityName }}</span>
                                                <span class="switcher-id" *ngIf="isWatershed()">
                                                    (WRIA
                                                    {{ item.EntityID }})</span
                                                >
                                            </div>
                                        </div>
                                    </ng-template>

                                    <ng-template #selectedTemplate let-item="item" let-config="config"> </ng-template>

                                    <ng-template #dropdownButton>
                                        <div class="switch-reach-explorer-entity">
                                            <label for="" class="primary-label">
                                                <field-definition
                                                    *ngIf="UserFilterObjectTypeEnum[userFilterObjectType] === 'Watershed'; else noWatershed"
                                                    [fieldDefinitionType]="'Watershed'"
                                                    [inline]="false"></field-definition>

                                                <ng-template #noWatershed>
                                                    {{ UserFilterObjectTypeEnum[userFilterObjectType] }}
                                                </ng-template>
                                            </label>

                                            <div class="switch-reach-explorer-entity__dropdown">
                                                <div class="current-wria" *ngIf="currentReachExplorerEntity">
                                                    {{ currentReachExplorerEntity.EntityName }}
                                                    <span class="wria-id" *ngIf="isWatershed()">
                                                        (WRIA
                                                        {{ currentReachExplorerEntity.EntityID }})</span
                                                    >
                                                </div>

                                                <icon icon="AngleDown"></icon>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ngx-select-dropdown
                                        [optionItemTemplate]="optionTemplate"
                                        [selectedItemTemplate]="selectedTemplate"
                                        [dropdownButtonTemplate]="dropdownButton"
                                        id="currentReachExplorerEntity"
                                        name="currentReachExplorerEntity"
                                        [(ngModel)]="currentReachExplorerEntity"
                                        [ngModelOptions]="{ standalone: true }"
                                        [config]="reachExplorerEntityDropdownConfig"
                                        [options]="reachExplorerEntities"
                                        (change)="changedReachExplorerEntity()">
                                    </ngx-select-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="filter-section">
                            <div class="filter-section-title" [expandCollapse]="filterBodySearchCriteria" [startOpen]="true">
                                <h4><span class="number">2</span>Search Criteria</h4>
                                <icon icon="AngleDown"></icon>
                            </div>
                            <div class="filter-section-body" #filterBodySearchCriteria>
                                <div class="filter">
                                    <label class="primary-label">
                                        <field-definition [fieldDefinitionType]="'TemperatureImpairedWaterbodies'" [inline]="false"></field-definition>
                                    </label>
                                    <div class="checkbox-group">
                                        <ul>
                                            <!--
                                        <li>
                                            <input
                                                type="checkbox"
                                                formControlName="AllTemperatureImpaired"
                                                id="AllTemperatureImpaired"
                                                checked
                                            />
                                            <label
                                                for="AllTemperatureImpaired"
                                                >All</label
                                            >
                                        </li>
                                        -->
                                            <li>
                                                <input type="checkbox" formControlName="FilterToEcology305BList" id="FilterToEcology305BList" />
                                                <label for="FilterToEcology305BList">Ecology 305(b) List</label>
                                            </li>
                                            <li>
                                                <input type="checkbox" formControlName="FilterToEcology303DList" id="FilterToEcology303DList" />
                                                <label for="FilterToEcology303DList">Ecology 303(d) List</label>
                                            </li>
                                            <li>
                                                <input type="checkbox" formControlName="FilterToNotTemperatureImpaired" id="FilterToNotTemperatureImpaired" />
                                                <label for="FilterToNotTemperatureImpaired">Not Impaired</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="filter">
                                    <label class="primary-label">
                                        <field-definition [fieldDefinitionType]="'FishPassageBarriers'" [inline]="false"></field-definition>
                                    </label>
                                    <div class="checkbox-group">
                                        <ul>
                                            <!--
                                        <li>
                                            <input
                                                type="checkbox"
                                                formControlName="AllFishBarriers"
                                                id="AllFishBarriers"
                                                checked
                                            />
                                            <label for="AllFishBarriers"
                                                >All</label
                                            >
                                        </li>
                                        -->
                                            <li>
                                                <input type="checkbox" formControlName="FilterToFishBarriersUnknownPassability" id="FilterToFishBarriersUnknownPassability" />
                                                <label for="FilterToFishBarriersUnknownPassability">Unknown Passability</label>
                                            </li>
                                            <li>
                                                <input type="checkbox" formControlName="FilterToFishBarriersNonPassable" id="FilterToFishBarriersNonPassable" />
                                                <label for="FilterToFishBarriersNonPassable">Not Passable</label>
                                            </li>
                                            <li>
                                                <input type="checkbox" formControlName="FilterToFishBarriersPassable" id="FilterToFishBarriersPassable" />
                                                <label for="FilterToFishBarriersPassable">Passable</label>
                                            </li>
                                            <li>
                                                <input type="checkbox" formControlName="FilterToFishBarriersNoKnownBarrier" id="FilterToFishBarriersNoKnownBarrier" />
                                                <label for="FilterToFishBarriersNoKnownBarrier">No Known Barrier</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="filter">
                                    <label class="primary-label">
                                        <field-definition [fieldDefinitionType]="'SWIFDSalmonDistribution'" [inline]="false"></field-definition>
                                    </label>
                                    <div class="checkbox-group">
                                        <ul>
                                            <!--
                                        <li>
                                            <input
                                                type="checkbox"
                                                formControlName="AllSalmonBearing"
                                                id="AllSalmonBearing"
                                                checked
                                            />
                                            <label
                                                for="AllSalmonBearing"
                                                >All</label
                                            >
                                        </li>
                                        -->
                                            <li>
                                                <input type="checkbox" formControlName="FilterToSalmonBearing" id="FilterToSalmonBearing" />
                                                <label for="FilterToSalmonBearing">Salmon Bearing</label>
                                            </li>
                                            <li>
                                                <input type="checkbox" formControlName="FilterToNonSalmonBearing" id="FilterToNonSalmonBearing" />
                                                <label for="FilterToNonSalmonBearing">Non-Salmon Bearing</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="filter">
                                    <label class="primary-label">
                                        <field-definition
                                            [fieldDefinitionType]="'PercentVegetated'"
                                            [labelOverride]="'Reach Percent Vegetated'"
                                            [inline]="false"></field-definition>
                                    </label>
                                    <div class="custom-slider">
                                        <ngx-slider formControlName="PercentageVegetated" [options]="rangeSliderOptions"></ngx-slider>
                                    </div>
                                </div>

                                <div class="filter">
                                    <label class="primary-label">
                                        <field-definition
                                            [fieldDefinitionType]="'PercentTreeCover'"
                                            [labelOverride]="'Reach Percent Tree Cover'"
                                            [inline]="false"></field-definition>
                                    </label>

                                    <div class="custom-slider">
                                        <ngx-slider formControlName="PercentageTreeCover" [options]="rangeSliderOptions"></ngx-slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="filter-footer">
                        <button class="btn btn-primary btn-sm mr-2" (click)="filter()" iconClass="" [disabled]="isLoadingReachExplorerEntities">Apply</button>

                        <button class="btn btn-sm btn-primary-outline" (click)="resetAndFilter()">Clear</button>
                    </div>
                </div>
            </div>
            <div
                class="g-col-9"
                [loadingSpinner]="{
                    isLoading: isLoadingReachExplorerEntities,
                    loadingHeight: 100,
                }">
                <ng-container *ngIf="currentReachExplorerEntity && reachSearchResult">
                    <div class="page-header">
                        <h1 class="page-title page-title__reachExplorerEntity">
                            <span class="icon-wrapper">
                                <icon icon="MapOpen"></icon>
                            </span>
                            {{ currentReachExplorerEntity.EntityName }}

                            {{ UserFilterObjectTypeEnum[userFilterObjectType] }}
                            <span class="wria-id" *ngIf="userFilterObjectType === UserFilterObjectTypeEnum.Watershed">
                                (WRIA
                                {{ currentReachExplorerEntity.EntityID }})</span
                            >
                        </h1>
                    </div>
                </ng-container>
                <btn-group-radio-input label="Test" [options]="tabs" (change)="setActiveTab($event)" [default]="activeTab" [showIcons]="true"></btn-group-radio-input>
                <!--
                TODO: RAY! ACTIVE FILTERS

            <div class="active-filters">
                <div class="active-filters__header">
                    <h3 class="active-filters__title">Active Filters</h3>
                </div>

                <div class="active-filters__body">
                    <div class="active-filter">
                        <div class="active-filter__remove">
                            <icon icon="Delete"></icon>
                        </div>
                        <div class="active-filter__name">
                            <span class="active-filter__key"
                                >Fish Passage Barriers:</span
                            >
                            <span class="active-filter__value"
                                >Unknown</span
                            >
                        </div>
                    </div>

                    <div class="active-filter">
                        <div class="active-filter__remove">
                            <icon icon="Delete"></icon>
                        </div>
                        <div class="active-filter__name">
                            <span class="active-filter__key"
                                >Temperature Impaired Water Bodies:</span
                            >
                            <span class="active-filter__value"
                                >Ecology 303(b) List</span
                            >
                        </div>
                    </div>

                    <div class="active-filter">
                        <div class="active-filter__remove">
                            <icon icon="Delete"></icon>
                        </div>
                        <div class="active-filter__name">
                            <span class="active-filter__key"
                                >Temperature Impaired Water Bodies:</span
                            >
                            <span class="active-filter__value"
                                >Ecology 303(d) List</span
                            >
                        </div>
                    </div>

                    <div class="active-filter">
                        <div class="active-filter__remove">
                            <icon icon="Delete"></icon>
                        </div>
                        <div class="active-filter__name">
                            <span class="active-filter__key"
                                >Tree Cover %:</span
                            >
                            <span class="active-filter__value"
                                >0% - 25%</span
                            >
                        </div>
                    </div>

                    <div class="active-filter">
                        <div class="active-filter__remove">
                            <icon icon="Delete"></icon>
                        </div>
                        <div class="active-filter__name">
                            <span class="active-filter__key"
                                >Non-Vegetated %:</span
                            >
                            <span class="active-filter__value"
                                >0% - 25%</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            -->
                <riparis-map (onMapLoad)="handleMapReady($event)" mapHeight="800px" [hidden]="activeTab !== 'Map'"> </riparis-map>

                <ng-template #noReaches> There are no reaches in your filter set. </ng-template>

                <div style="height: 800px" [hidden]="activeTab !== 'LandCover'">
                    <div>
                        <h3 class="chartTitle">Land Cover by Type</h3>
                        <hr />
                        <land-cover-bar-chart *ngIf="landCoverChartData?.length > 0; else noReaches" [chartData]="landCoverChartData"></land-cover-bar-chart>
                    </div>
                </div>

                <div style="height: 800px" [hidden]="activeTab !== 'Hydrology'">
                    <div>
                        <h3 class="chartTitle">Area by Reach Zone</h3>
                        <hr />
                        <zone-area-radial-chart *ngIf="zoneAreaChartData?.length > 0; else noReaches" [chartData]="zoneAreaChartData"></zone-area-radial-chart>
                    </div>
                </div>

                <div style="height: 800px" [hidden]="activeTab !== 'Salmon'">
                    <div>
                        <h3 class="chartTitle">Salmon Distribution by Species</h3>
                        <hr />
                        <salmon-distribution-bar-chart
                            *ngIf="salmonDistributionChartData?.length > 0; else noReaches"
                            [chartData]="salmonDistributionChartData"></salmon-distribution-bar-chart>
                    </div>
                </div>

                <div style="height: 800px" [hidden]="activeTab !== 'Administrative'">
                    <div>
                        <h3 class="chartTitle">Area by Land Use</h3>
                        <hr />
                        <land-use-waffle-chart *ngIf="landUseChartData?.length > 0; else noReaches" [chartData]="landUseChartData"></land-use-waffle-chart>
                    </div>
                </div>

                <div [hidden]="activeTab !== 'Reach'">
                    <div class="search-results mt-4" *ngIf="currentReachExplorerEntity && reachSearchResult">
                        <div class="grid-12">
                            <div class="g-col-6">
                                <h4 class="search-results-title">
                                    <field-definition
                                        [fieldDefinitionType]="'FilteredRiparianAcres'"
                                        [inline]="false"
                                        [labelOverride]="'Filtered Riparian Acres (% of Total):'"></field-definition>
                                    <span class="value pl-2"
                                        >{{ currentReachExplorerEntity.RiparianAcres | number: "1.0-0" }} ({{
                                            reachSearchResult.RiparianAcres / currentReachExplorerEntity.RiparianAcres | percent: "1.0-2"
                                        }})</span
                                    >
                                </h4>
                            </div>
                            <div class="g-col-6">
                                <h4 class="search-results-title">
                                    <field-definition
                                        [fieldDefinitionType]="'FilteredStreamMiles'"
                                        [inline]="false"
                                        [labelOverride]="'Filtered Stream Miles (% of Total):'"></field-definition>
                                    <span class="value pl-2"
                                        >{{ currentReachExplorerEntity.StreamMiles | number: "1.0-0" }} ({{
                                            reachSearchResult.StreamMiles / currentReachExplorerEntity.StreamMiles | percent: "1.0-2"
                                        }})</span
                                    >
                                </h4>
                            </div>
                        </div>
                        <div class="mt-2">
                            <riparis-grid
                                height="600px"
                                [rowData]="reachSearchResult.Reaches"
                                [columnDefs]="columnDefs"
                                downloadDisplayName="reaches-shown"
                                [pagination]="true"
                                [sizeColumnsToFitGrid]="true"
                                (gridReady)="onGridReady($event)"
                                rowSelection="single">
                            </riparis-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
