import { Component, Input } from "@angular/core";
import { default as vegaEmbed, VisualizationSpec } from "vega-embed";
import { LoadingDirective } from "../../../directives/loading.directive";
import { ChartData } from "../ChartData";
import { DecimalPipe, KeyValuePipe, NgForOf, NgIf, PercentPipe } from "@angular/common";

@Component({
    selector: "land-use-waffle-chart",
    standalone: true,
    imports: [LoadingDirective, KeyValuePipe, NgIf, NgForOf, DecimalPipe, PercentPipe],
    templateUrl: "./land-use-waffle-chart.component.html",
    styleUrl: "./land-use-waffle-chart.component.scss",
})
export class LandUseWaffleChartComponent {
    @Input() chartData: ChartData[];
    @Input() chartHeight: number = 350;
    @Input() isLoading: boolean = false;

    public chartID: string = "landUseWaffleChart";
    public colorRange: Map<string, string> = new Map([
        ["Agriculture", "#FFE89C"],
        ["Agriculture (not classified)", "#FFD965"],
        ["Agriculture (ch 83.34 RCW)", "#FFC000"],
        ["Commercial/Industrial", "#FC9272"],
        ["Forestry (ch 84.33 RCW)", "#A1D99B"],
        ["Forestry (ch 84.34 RCW)", "#74C476"],
        ["Open Space (ch 84.34 RCW)", "#DAE5EB"],
        ["Resource Production & Extraction", "#73FFDF"],
        ["Residential", "#9E9AC8"],
        ["Infrastructure", "#756BB1"],
        ["Undeveloped Land & Water Areas", "#B3D6EF"],
        ["Undeveloped Lands (91)", "#999999"],
    ]);

    private fixPercentageRounding(values): Array<number> {
        const flooredValues = values.map((e) => Math.floor(e));
        const remainders = values.map((e) => e - Math.floor(e));
        const totalRemainder = 100 - flooredValues.reduce((a, b) => a + b);

        // Deep copy because order of remainders is important
        [...remainders]
            // Sort from highest to lowest remainder
            .sort((a, b) => b - a)
            // Get the n largest remainder values, where n = totalRemainder
            .slice(0, totalRemainder)
            // Add 1 to the floored percentages with the highest remainder (divide the total remainder)
            .forEach((e) => (flooredValues[remainders.indexOf(e)] += 1));

        return flooredValues;
    }

    ngOnChanges() {
        const percentagesFixed = this.fixPercentageRounding(this.chartData.map((x) => x.HoverValue * 100));
        for (let i = 0; i < percentagesFixed.length; i++) {
            this.chartData[i].Percentage = percentagesFixed[i];
        }

        const vegaSpec = {
            $schema: "https://vega.github.io/schema/vega-lite/v5.json",
            width: this.chartHeight,
            height: this.chartHeight,
            autosize: {
                type: "fit",
                resize: true,
            },
            config: {
                legend: {
                    disable: true,
                },
            },
            data: {
                name: "table",
                values: this.chartData,
            },
            transform: [
                { calculate: "sequence(1,datum.Percentage+1)", as: "S" },
                { flatten: ["S"] },
                {
                    window: [{ op: "row_number", as: "id" }],
                    sort: [{ field: "XValue", order: "descending" }],
                },
                { calculate: "ceil (datum.id / 10)", as: "row" },
                { calculate: "datum.id - datum.row * 10", as: "col" },
            ],
            mark: {
                type: "square",
                filled: true,
                tooltip: true,
                stroke: "black",
                strokeWidth: 0.7,
            },
            encoding: {
                x: { field: "col", type: "ordinal", axis: null },
                y: { field: "row", type: "ordinal", axis: null, sort: "-y" },
                color: {
                    sort: "ascending",
                    field: "XValue",
                    title: "",
                    type: "nominal",
                    scale: {
                        domain: [...this.colorRange.keys()],
                        range: [...this.colorRange.values()],
                    },
                },
                size: { value: 1000 },
                tooltip: [
                    { field: "XValue", title: "Land Use", type: "ordinal" },
                    { field: "YValue", title: "Acres", type: "quantitative", format: ",.1f" },
                    { field: "HoverValue", title: "% of Total", type: "quantitative", format: ".1%" },
                ],
            },
        } as VisualizationSpec;

        vegaEmbed(`#${this.chartID}`, vegaSpec, { renderer: "svg" }).then((res) => {});
    }
}
