import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { CommonModule, DecimalPipe, NgIf, PercentPipe } from "@angular/common";
import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { IconComponent } from "../icon/icon.component";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Options, NgxSliderModule } from "@angular-slider/ngx-slider";
import { SelectDropDownModule } from "ngx-select-dropdown";
import { Subscription } from "rxjs";
import { routeParams } from "src/app/app.routes";
import { environment } from "src/environments/environment";
import { ExpandCollapseDirective } from "../../directives/expand-collapse.directive";
import { LoadingDirective } from "../../directives/loading.directive";
import { ReachService } from "../../generated/api/reach.service";
import { ReachSearchRequestDtoFormControls } from "../../generated/model/reach-search-request-dto";
import { ReachSearchResultDto } from "../../generated/model/reach-search-result-dto";
import { AlertService } from "../../services/alert.service";
import { UtilityFunctionsService } from "../../services/utility-functions.service";
import { WfsService } from "../../services/wfs.service";
import { AlertDisplayComponent } from "../alert-display/alert-display.component";
import { FieldDefinitionComponent } from "../field-definition/field-definition.component";
import { BtnGroupRadioInputComponent } from "../inputs/btn-group-radio-input/btn-group-radio-input.component";
import { MapLayerBase } from "../leaflet/layers/map-layer-base.component";
import { RiparisMapComponent, RiparisMapInitEvent } from "../leaflet/riparis-map/riparis-map.component";
import { RiparisGridComponent } from "../riparis-grid/riparis-grid.component";
import * as L from "leaflet";
import "src/scripts/leaflet.groupedlayercontrol.js";
import { UserFilterObjectTypeEnum } from "../../generated/enum/user-filter-object-type-enum";
import { LandCoverBarChartComponent } from "src/app/shared/components/charts/land-cover-bar-chart/land-cover-bar-chart.component";
import { ChartData } from "../charts/ChartData";
import { NetworkStatisticsDto } from "../../models/network-statistics-dto";
import { SumPipe } from "../../pipes/sum.pipe";
import { ZoneAreaRadialChartComponent } from "../charts/zone-area-radial-chart/zone-area-radial-chart.component";
import { Title } from "@angular/platform-browser";
import { SalmonDistributionBarChartComponent } from "../charts/salmon-distribution-bar-chart/salmon-distribution-bar-chart.component";
import { LandUseWaffleChartComponent } from "../charts/land-use-waffle-chart/land-use-waffle-chart.component";

@Component({
    selector: "reach-explorer",
    templateUrl: "./reach-explorer.component.html",
    styleUrls: ["./reach-explorer.component.scss"],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        IconComponent,
        ExpandCollapseDirective,
        NgIf,
        SelectDropDownModule,
        FieldDefinitionComponent,
        NgxSliderModule,
        LoadingDirective,
        RiparisMapComponent,
        RiparisGridComponent,
        AlertDisplayComponent,
        DecimalPipe,
        PercentPipe,
        SumPipe,
        BtnGroupRadioInputComponent,
        LandCoverBarChartComponent,
        SalmonDistributionBarChartComponent,
        ZoneAreaRadialChartComponent,
        CommonModule,
        MapLayerBase,
        LandUseWaffleChartComponent,
    ],
})
export class ReachExplorerComponent implements OnInit, OnDestroy, OnChanges {
    @Input() userFilterObjectType: UserFilterObjectTypeEnum;
    @Input() reachExplorerEntities: ReachExplorerEntityDto[];
    @Input() currentReachExplorerEntity: ReachExplorerEntityDto;
    @Input() userID: number;

    public UserFilterObjectTypeEnum = UserFilterObjectTypeEnum;
    public reachSearchResult: ReachSearchResultDto;

    private allReachExplorerEntitiesFeatureGroup = L.featureGroup();
    public isLoadingReachExplorerEntities: boolean = false;

    public wmsOptionsFilteredReaches: L.WMSOptions;
    public wmsOptionsAllReaches: L.WMSOptions;
    public wmsOptionsFilteredReachZones: L.WMSOptions;
    public wmsOptionsAllReachZones: L.WMSOptions;
    public wmsOptionsAllLandCovers: L.WMSOptions;
    public allReachesLayer;
    public filteredReachesLayer;
    public allReachZonesLayer;
    public filteredReachZonesLayer;
    private selectedReachZoneLayer: any;
    public allLandCoversLayer;
    public allNetworkStatistics: NetworkStatisticsDto;
    public landCoverChartData: ChartData[];
    public salmonDistributionChartData: ChartData[];
    public zoneAreaChartData: ChartData[];
    public landUseChartData: ChartData[];

    public activeTab: string = "Map";
    public tabs = [
        { label: "Map", value: "Map" },
        { label: "Land Cover", value: "LandCover" },
        { label: "Water Quality", value: "WaterQuality", disabled: true },
        { label: "Hydrology", value: "Hydrology" },
        { label: "Salmon", value: "Salmon" },
        { label: "Administrative", value: "Administrative" },
        { label: "Reach Table", value: "Reach" },
    ];

    public gridApi: GridApi;

    public columnDefs: (ColDef | ColGroupDef)[];

    public reachExplorerEntityDropdownConfig = {
        search: true,
        height: "320px",
        placeholder: "",
        searchOnKey: "EntityIDAndName",
    };

    public formGroup: FormGroup<any> = new FormGroup<any>({
        EntityID: ReachSearchRequestDtoFormControls.EntityID(),
        PercentageTreeCover: ReachSearchRequestDtoFormControls.PercentageTreeCover([0, 100]),
        PercentageVegetated: ReachSearchRequestDtoFormControls.PercentageVegetated([0, 100]),
        FilterToEcology305BList: ReachSearchRequestDtoFormControls.FilterToEcology305BList(),
        FilterToEcology303DList: ReachSearchRequestDtoFormControls.FilterToEcology303DList(),
        FilterToNotTemperatureImpaired: ReachSearchRequestDtoFormControls.FilterToNotTemperatureImpaired(),
        FilterToFishBarriersUnknownPassability: ReachSearchRequestDtoFormControls.FilterToFishBarriersUnknownPassability(),
        FilterToFishBarriersNonPassable: ReachSearchRequestDtoFormControls.FilterToFishBarriersNonPassable(),
        FilterToFishBarriersPassable: ReachSearchRequestDtoFormControls.FilterToFishBarriersPassable(),
        FilterToFishBarriersNoKnownBarrier: ReachSearchRequestDtoFormControls.FilterToFishBarriersNoKnownBarrier(),
        FilterToSalmonBearing: ReachSearchRequestDtoFormControls.FilterToSalmonBearing(),
        FilterToNonSalmonBearing: ReachSearchRequestDtoFormControls.FilterToNonSalmonBearing(),
    });

    rangeSliderOptions: Options = {
        floor: 0,
        ceil: 100,
        step: 5,
    };

    private defaultStyle = {
        color: "#004c73",
        weight: 2.5,
        fillOpacity: 0,
    };

    private geoserverWFSSubscription: Subscription = Subscription.EMPTY;
    private reachExplorerEntityIDRouteParam: any;

    constructor(
        private reachService: ReachService,
        private alertService: AlertService,
        private utilityFunctionsService: UtilityFunctionsService,
        private router: Router,
        private route: ActivatedRoute,
        private wfsService: WfsService,
        private sumPipe: SumPipe,
        private title: Title
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.mapIsReady && Object.keys(changes).includes("currentReachExplorerEntity")) {
            if (this.selectedReachZoneLayer) {
                this.map.removeLayer(this.selectedReachZoneLayer);
                this.selectedReachZoneLayer = null;
            }

            this.updateReachExplorerEntity();
            this.displayReachExplorerEntity();
            this.updateReachZones();
        }
    }

    ngOnDestroy(): void {
        this.geoserverWFSSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.reachExplorerEntityIDRouteParam = params[routeParams.reachExplorerEntityID];
        });
    }

    private updateReachExplorerEntity() {
        this.title.setTitle(`${this.currentReachExplorerEntity.EntityName} ${UserFilterObjectTypeEnum[this.userFilterObjectType]} | Map Explorer | Riparian Data Engine`);
        this.formGroup.controls.EntityID.patchValue(this.currentReachExplorerEntity.EntityID);
        this.displayReachExplorerEntity();
        this.displayAllReaches();
        this.resetAndFilter();
    }

    public setActiveTab(event) {
        this.activeTab = event;
        if (this.activeTab === "LandCover") {
            this.createLandCoverChartData();
        } else if (this.activeTab === "Hydrology") {
            this.createZoneAreaChartData();
        } else if (this.activeTab === "Salmon") {
            this.createSalmonDistributionChartData();
        } else if (this.activeTab === "Administrative") {
            this.createLandUseChartData();
        }
    }

    // the map stuff
    public map: L.Map;
    public layerControl: L.groupedlayercontrol;
    public mapIsReady: boolean = false;

    handleMapReady(event: RiparisMapInitEvent): void {
        this.map = event.map;
        this.layerControl = event.layerControl;
        this.mapIsReady = true;

        this.createColumnDefs();
        this.updateReachExplorerEntity();
        const wfsService = this.wfsService;
        const self = this;

        const zoomToReachExplorerEntityButton = L.control({ position: "topleft" });
        zoomToReachExplorerEntityButton.onAdd = () => {
            const buttonDiv = L.DomUtil.create("a", "leaflet-control-zoom-custom");
            buttonDiv.role = "button";
            buttonDiv.title = "Zoom to " + this.userFilterObjectType + " Extent";

            buttonDiv.innerHTML = `<svg width="18" height="30" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.57143 11.5H1V7.92857M7.42857 1.5H11V5.07143" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`;
            buttonDiv.addEventListener("click", () => self.zoomToReachExplorerEntity());
            return buttonDiv;
        };
        zoomToReachExplorerEntityButton.addTo(this.map);
        this.map.fullscreenControl._container.appendChild(zoomToReachExplorerEntityButton._container);

        this.map.on("click", (event: L.LeafletMouseEvent): void => {
            self.map.fireEvent("dataloading");
            wfsService.getPIDByCoordinate(event.latlng.lng, event.latlng.lat).subscribe((reachPID: string) => {
                const startIndex = reachPID.indexOf("<Riparis:PermanentIdentifier>");
                const endIndex = reachPID.indexOf("</Riparis:PermanentIdentifier>");
                self.map.fireEvent("dataload");
                if (startIndex < 0 && endIndex < 0) {
                    return;
                }

                const permanentIdentifier = reachPID.substring(startIndex + 29, endIndex);

                // IMPORTANT: THIS ONLY WORKS BECAUSE I'VE INSTALLED @angular/elements AND CONFIGURED THIS IN THE app.module.ts bootstrapping
                L.popup({
                    maxWidth: 250,
                    keepInView: true,
                })
                    .setLatLng(event.latlng)
                    .setContent(`<reach-popup-custom-element permanent-identifier="${permanentIdentifier}"></reach-popup-custom-element>`)
                    .openOn(self.map);
            });
        });
    }

    private createColumnDefs() {
        this.columnDefs = [
            this.utilityFunctionsService.createLinkColumnDef("PID", "PermanentIdentifier", "PermanentIdentifier", {
                InRouterLink: "/reaches/",
                Target: "_blank",
                FieldDefinitionType: "PermanentIdentifier",
                Width: 200,
            }),
            this.utilityFunctionsService.createBasicColumnDef("Stream", "StreamName", { Width: 150, FieldDefinitionType: "StreamName" }),
            this.utilityFunctionsService.createDecimalColumnDef("Total Reach Acres", "ReachAcres", {
                Width: 150,
                FieldDefinitionType: "Acres",
                FieldDefinitionLabelOverride: "Total Reach (ac)",
            }),
            this.utilityFunctionsService.createBasicColumnDef("Zones", "Zones", { Width: 100, FieldDefinitionType: "Zones" }),
            this.utilityFunctionsService.createDecimalColumnDef("% Tree Cover", "PercentTreeCover", {
                Width: 150,
                FieldDefinitionType: "PercentTreeCover",
                FieldDefinitionLabelOverride: "% Tree Cover",
            }),
            this.utilityFunctionsService.createDecimalColumnDef("% Vegetated", "PercentVegetated", {
                Width: 135,
                FieldDefinitionType: "PercentVegetated",
                FieldDefinitionLabelOverride: "% Vegetated",
            }),
            this.utilityFunctionsService.createDecimalColumnDef("Natural Change Acres", "NaturalChangeOriginAcres", {
                Width: 175,
                FieldDefinitionType: "NaturalChangeAcres",
                DecimalPlacesToDisplay: 1,
                FieldDefinitionLabelOverride: "Natural Change (ac)",
            }),
            this.utilityFunctionsService.createDecimalColumnDef("Anthropogenic Change Acres", "AnthropogenicChangeOriginAcres", {
                Width: 225,
                FieldDefinitionType: "AnthropogenicChangeAcres",
                DecimalPlacesToDisplay: 1,
                FieldDefinitionLabelOverride: "Anthropogenic Change (ac)",
            }),
            this.utilityFunctionsService.createBasicColumnDef("Temperature Impairments", "TemperatureImpairments", {
                Width: 225,
                FieldDefinitionType: "TemperatureImpairedWaterbodies",
                FieldDefinitionLabelOverride: "Temperature Impairements",
            }),
            this.utilityFunctionsService.createBasicColumnDef("SWIFD Salmon Distribution", "SWIFDSalmonDistribution", { FieldDefinitionType: "SWIFDSalmonDistribution" }),
            {
                headerName: "Ownership (ac)",
                children: [
                    this.utilityFunctionsService.createDecimalColumnDef("Federal", "FederalAcres", { Width: 150, FieldDefinitionType: "Federal" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Municipal", "MunicipalAcres", { Width: 150, FieldDefinitionType: "Municipal" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Private/NGO", "PrivateNGOAcres", { Width: 150, FieldDefinitionType: "PrivateNGO" }),
                    this.utilityFunctionsService.createDecimalColumnDef("State", "StateAcres", { Width: 150, FieldDefinitionType: "State" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Tribal", "TribalAcres", { Width: 150, FieldDefinitionType: "Tribal" }),
                ],
            },
            {
                headerName: "Land Use (ac)",
                children: [
                    this.utilityFunctionsService.createDecimalColumnDef("Agriculture Acres", "AgricultureAcres", { Width: 150, FieldDefinitionType: "Agriculture" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Agriculture Ch 83 Acres", "AgricultureChapter83Acres", {
                        Width: 220,
                        FieldDefinitionType: "AgricultureNotClassified",
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("AgricultureNotLaw81Acres", "AgricultureNotLaw81Acres", {
                        Width: 225,
                        FieldDefinitionType: "AgricultureChapter8334",
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("Commercial Acres", "CommercialAcres", { Width: 200, FieldDefinitionType: "CommericalIndustrial" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Forestry Forest Land Acres", "ForestryForestLandAcres", {
                        Width: 200,
                        FieldDefinitionType: "ForestryForest",
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("Forest Timberland Acres", "ForestTimberlandDataAcres", {
                        Width: 200,
                        FieldDefinitionType: "ForestryTimberland",
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("Open Space Acres", "OpenSpaceAcres", { Width: 220, FieldDefinitionType: "OpenSpace" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Residential Acres", "ResidentialAcres", { Width: 150, FieldDefinitionType: "Residential" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Resource Acres", "ResourceAcres", { Width: 245, FieldDefinitionType: "ResourceProductionAndExtraction" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Transportation Acres", "TransportationAcres", { Width: 150, FieldDefinitionType: "Infrastructure" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Undeveloped Land Acres", "UndevelopedLandAcres", {
                        Width: 245,
                        FieldDefinitionType: "UndevelopedLandAndWaterAreas",
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("Undeveloped Lands 91 Acres", "UndevelopedLands91Acres", {
                        Width: 200,
                        FieldDefinitionType: "UndevelopedLands",
                    }),
                ],
            },
            this.utilityFunctionsService.createDecimalColumnDef("Canopy Pattern Metric", "CanopyPatternMetric", { Width: 200, FieldDefinitionType: "CanopyPatternMetric" }),
            {
                headerName: "Topography",
                children: [
                    this.utilityFunctionsService.createBasicColumnDef("Bank Aspects", "BankAspects", { Width: 150, FieldDefinitionType: "BankAspects" }),
                    this.utilityFunctionsService.createDecimalColumnDef("Average Canopy Height", "AverageCanopyHeight", {
                        Width: 225,
                        FieldDefinitionType: "AverageCanopyHeight",
                        FieldDefinitionLabelOverride: "Average Canopy Height (ft)",
                        DecimalPlacesToDisplay: 0,
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("Average Elevation", "AverageElevation", {
                        Width: 200,
                        FieldDefinitionType: "AverageElevation",
                        FieldDefinitionLabelOverride: "Average Elevation (ft)",
                        DecimalPlacesToDisplay: 0,
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("Average Slope", "AverageSlope", {
                        Width: 200,
                        FieldDefinitionType: "AverageSlope",
                        FieldDefinitionLabelOverride: "Average Slope (degrees)",
                        DecimalPlacesToDisplay: 0,
                    }),
                    this.utilityFunctionsService.createDecimalColumnDef("Average Solar", "AverageSolar", {
                        Width: 200,
                        FieldDefinitionType: "AverageSolar",
                        FieldDefinitionLabelOverride: "Average Solar (WH/m^2)",
                        DecimalPlacesToDisplay: 0,
                    }),
                ],
            },
        ];
    }

    private displayReachExplorerEntity(): void {
        this.geoserverWFSSubscription.unsubscribe();
        let cql_filter: string;
        let wfsLayerName: string;
        switch (this.userFilterObjectType) {
            case UserFilterObjectTypeEnum.Watershed:
                cql_filter = `WaterResourceInventoryAreaID in (${this.currentReachExplorerEntity.EntityID})`;
                wfsLayerName = "Riparis:Wrias";
                break;
            case UserFilterObjectTypeEnum.County:
                cql_filter = `CountyID in (${this.currentReachExplorerEntity.EntityID})`;
                wfsLayerName = "Riparis:Counties";
                break;
            case UserFilterObjectTypeEnum.City_UrbanGrowthArea:
                cql_filter = `CityUrbanGrowthAreaID in (${this.currentReachExplorerEntity.EntityID})`;
                wfsLayerName = "Riparis:CityUrbanGrowthAreas";
                break;
        }

        this.geoserverWFSSubscription = this.wfsService.getGeoserverWFSLayerWithCQLFilter(wfsLayerName, cql_filter).subscribe((response) => {
            this.allReachExplorerEntitiesFeatureGroup.clearLayers();
            const geoJson = L.geoJSON(response, {
                style: this.defaultStyle,
            });
            this.allReachExplorerEntitiesFeatureGroup.addLayer(geoJson);
            this.allReachExplorerEntitiesFeatureGroup.addTo(this.map);
            this.zoomToReachExplorerEntity();
        });
    }

    private zoomToReachExplorerEntity() {
        this.map.fitBounds(this.allReachExplorerEntitiesFeatureGroup.getBounds());
    }

    public filter() {
        this.isLoadingReachExplorerEntities = true;
        this.reachService.reachesSearchUserFilterObjectTypeIDPost(this.userFilterObjectType, this.formGroup.value).subscribe((response) => {
            this.isLoadingReachExplorerEntities = false;
            this.alertService.clearAlerts();
            this.reachSearchResult = response;
            this.createLandCoverChartData();
            this.createZoneAreaChartData();
            this.createSalmonDistributionChartData();
            this.createLandUseChartData();
            this.updateReachZones();
        });
    }

    private displayAllReaches(): void {
        if (this.allLandCoversLayer && this.layerControl) {
            this.map.removeLayer(this.allLandCoversLayer);
            this.layerControl.removeLayer(this.allLandCoversLayer);
        }
        if (this.allReachesLayer && this.layerControl) {
            this.map.removeLayer(this.allReachesLayer);
            this.layerControl.removeLayer(this.allReachesLayer);
        }
        if (this.allReachZonesLayer && this.layerControl) {
            this.map.removeLayer(this.allReachZonesLayer);
            this.layerControl.removeLayer(this.allReachZonesLayer);
        }

        let cql_filter: string;
        switch (this.userFilterObjectType) {
            case UserFilterObjectTypeEnum.Watershed:
                cql_filter = `WaterResourceInventoryAreaID in (${this.currentReachExplorerEntity.EntityID})`;
                break;
            case UserFilterObjectTypeEnum.County:
                cql_filter = `CountyID in (${this.currentReachExplorerEntity.EntityID})`;
                break;
            case UserFilterObjectTypeEnum.City_UrbanGrowthArea:
                cql_filter = `CityUrbanGrowthAreaID in (${this.currentReachExplorerEntity.EntityID})`;
                break;
        }

        this.wmsOptionsAllReaches = {
            layers: "Riparis:AllStreams",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "stream",
        };
        this.wmsOptionsAllReachZones = {
            layers: "Riparis:AllReachZones",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "reachzone",
        };
        this.wmsOptionsAllLandCovers = {
            layers: "Riparis:LandCovers",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "landcover",
        };

        this.wmsOptionsAllReaches.cql_filter = cql_filter;
        this.wmsOptionsAllReachZones.cql_filter = cql_filter;
        this.wmsOptionsAllLandCovers.cql_filter = cql_filter;
        this.allReachesLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", this.wmsOptionsAllReaches);
        this.allReachZonesLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", this.wmsOptionsAllReachZones);
        this.allLandCoversLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", this.wmsOptionsAllLandCovers);
        this.layerControl.addOverlay(this.allLandCoversLayer, "Land Cover", "Land Cover");
    }

    private updateReachZones(): void {
        if (this.allReachesLayer && this.layerControl) {
            this.map.removeLayer(this.allReachesLayer);
            this.layerControl.removeLayer(this.allReachesLayer);
        }
        if (this.allReachZonesLayer && this.layerControl) {
            this.map.removeLayer(this.allReachZonesLayer);
            this.layerControl.removeLayer(this.allReachZonesLayer);
        }
        if (this.filteredReachesLayer && this.layerControl) {
            this.map.removeLayer(this.filteredReachesLayer);
            this.layerControl.removeLayer(this.filteredReachesLayer);
        }

        if (this.filteredReachZonesLayer && this.layerControl) {
            this.map.removeLayer(this.filteredReachZonesLayer);
            this.layerControl.removeLayer(this.filteredReachZonesLayer);
        }

        const cql_filter = `UserID = ${this.userID} and UserFilterObjectTypeID = ${this.userFilterObjectType}`;
        this.wmsOptionsFilteredReaches = {
            layers: "Riparis:Streams",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "stream_selected",
            fake: Date.now(),
        };

        this.wmsOptionsFilteredReachZones = {
            layers: "Riparis:ReachZones",
            transparent: true,
            format: "image/png",
            tiled: true,
            styles: "reachzone_selected",
            fake: Date.now(),
        };

        this.wmsOptionsFilteredReaches.cql_filter = cql_filter;
        this.wmsOptionsFilteredReachZones.cql_filter = cql_filter;
        this.filteredReachesLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", this.wmsOptionsFilteredReaches);
        this.filteredReachZonesLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + "/wms?", this.wmsOptionsFilteredReachZones);
        this.layerControl.addOverlay(this.allReachZonesLayer, "All", "Riparian Areas");
        this.map.addLayer(this.allReachZonesLayer);
        this.layerControl.addOverlay(this.filteredReachZonesLayer, "Filtered", "Riparian Areas");
        this.map.addLayer(this.filteredReachZonesLayer);
        this.layerControl.addOverlay(this.allReachesLayer, "All", "Streams");
        this.map.addLayer(this.allReachesLayer);
        this.layerControl.addOverlay(this.filteredReachesLayer, "Filtered", "Streams");
        this.map.addLayer(this.filteredReachesLayer);

        if (this.selectedReachZoneLayer) {
            this.map.removeLayer(this.selectedReachZoneLayer);
            this.map.addLayer(this.selectedReachZoneLayer);
        }
    }

    public reset() {
        this.formGroup.controls.PercentageTreeCover.patchValue([0, 100]);
        this.formGroup.controls.PercentageVegetated.patchValue([0, 100]);
        this.formGroup.controls.FilterToEcology305BList.patchValue(true);
        this.formGroup.controls.FilterToEcology303DList.patchValue(true);
        this.formGroup.controls.FilterToNotTemperatureImpaired.patchValue(true);
        this.formGroup.controls.FilterToFishBarriersUnknownPassability.patchValue(true);
        this.formGroup.controls.FilterToFishBarriersNonPassable.patchValue(true);
        this.formGroup.controls.FilterToFishBarriersPassable.patchValue(true);
        this.formGroup.controls.FilterToFishBarriersNoKnownBarrier.patchValue(true);
        this.formGroup.controls.FilterToSalmonBearing.patchValue(true);
        this.formGroup.controls.FilterToNonSalmonBearing.patchValue(true);
        this.formGroup.controls.EntityID.patchValue(this.currentReachExplorerEntity.EntityID);
    }

    public resetAndFilter() {
        this.reset();
        this.filter();
    }

    public changedReachExplorerEntity() {
        this.router.navigateByUrl(this.router.url.replace(this.reachExplorerEntityIDRouteParam, this.currentReachExplorerEntity.EntityID.toString()));
    }

    public onGridReady(event: GridReadyEvent) {
        this.gridApi = event.api;
    }

    public isWatershed() {
        return this.userFilterObjectType === UserFilterObjectTypeEnum.Watershed;
    }

    private calculateTotalNetworkStatistics(): NetworkStatisticsDto {
        const networkStatisticsDto = new NetworkStatisticsDto();
        networkStatisticsDto.ReachCount = this.reachSearchResult.Reaches.length;
        networkStatisticsDto.TotalAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "ReachAcres");
        networkStatisticsDto.AllLandCoverAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "AllLandCoverAcres");
        networkStatisticsDto.EOWAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "EOWAcres");
        networkStatisticsDto.RMZAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "RMZAcres");
        networkStatisticsDto.FloodAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "FloodAcres");
        networkStatisticsDto.EOWVegetatedAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "EOWVegetatedAcres");
        networkStatisticsDto.RMZVegetatedAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "RMZVegetatedAcres");
        networkStatisticsDto.FloodVegetatedAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "FloodVegetatedAcres");
        networkStatisticsDto.EOWTreeCoverAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "EOWTreeCoverAcres");
        networkStatisticsDto.RMZTreeCoverAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "RMZTreeCoverAcres");
        networkStatisticsDto.FloodTreeCoverAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "FloodTreeCoverAcres");
        networkStatisticsDto.EOWHerbaceousAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "EOWHerbaceousAcres");
        networkStatisticsDto.RMZHerbaceousAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "RMZHerbaceousAcres");
        networkStatisticsDto.FloodHerbaceousAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "FloodHerbaceousAcres");
        networkStatisticsDto.EOWShrubAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "EOWShrubAcres");
        networkStatisticsDto.RMZShrubAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "RMZShrubAcres");
        networkStatisticsDto.FloodShrubAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "FloodShrubAcres");
        networkStatisticsDto.EOWWaterAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "EOWWaterAcres");
        networkStatisticsDto.RMZWaterAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "RMZWaterAcres");
        networkStatisticsDto.FloodWaterAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "FloodWaterAcres");
        networkStatisticsDto.EOWNoVegetationDataAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "EOWNoVegetationDataAcres");
        networkStatisticsDto.RMZNoVegetationDataAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "RMZNoVegetationDataAcres");
        networkStatisticsDto.FloodNoVegetationDataAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "FloodNoVegetationDataAcres");
        networkStatisticsDto.EOWNonVegetatedAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "EOWNonVegetatedAcres");
        networkStatisticsDto.RMZNonVegetatedAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "RMZNonVegetatedAcres");
        networkStatisticsDto.FloodNonVegetatedAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "FloodNonVegetatedAcres");
        networkStatisticsDto.MilesOfStream = this.sumPipe.transform(this.reachSearchResult.Reaches, "StreamMiles");
        networkStatisticsDto.MilesOfSalmonBearingStream = this.sumPipe.transform(
            this.reachSearchResult.Reaches.filter((x) => x.SWIFDSalmonDistribution !== "Non-Salmon Bearing"),
            "StreamMiles"
        );
        networkStatisticsDto.MilesOfTemperatureImpairedStream = this.sumPipe.transform(
            this.reachSearchResult.Reaches.filter((x) => x.TemperatureImpairments.includes("5")),
            "StreamMiles"
        );
        networkStatisticsDto.FishPassageBarriersCount = this.sumPipe.transform(this.reachSearchResult.Reaches, "FishPassageBarriersCount");
        networkStatisticsDto.ImpassableFishPassageBarriersCount = this.sumPipe.transform(this.reachSearchResult.Reaches, "ImpassableFishPassageBarriersCount");
        networkStatisticsDto.ChinookSalmonDocumentedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "ChinookSalmonDocumentedMiles");
        networkStatisticsDto.ChinookSalmonPresumedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "ChinookSalmonPresumedMiles");
        networkStatisticsDto.ChinookSalmonGradientAccessibleMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "ChinookSalmonGradientAccessibleMiles");
        networkStatisticsDto.ChumSalmonDocumentedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "ChumSalmonDocumentedMiles");
        networkStatisticsDto.ChumSalmonPresumedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "ChumSalmonPresumedMiles");
        networkStatisticsDto.ChumSalmonGradientAccessibleMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "ChumSalmonGradientAccessibleMiles");
        networkStatisticsDto.CohoSalmonDocumentedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "CohoSalmonDocumentedMiles");
        networkStatisticsDto.CohoSalmonPresumedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "CohoSalmonPresumedMiles");
        networkStatisticsDto.CohoSalmonGradientAccessibleMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "CohoSalmonGradientAccessibleMiles");
        networkStatisticsDto.KokaneeSalmonDocumentedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "KokaneeSalmonDocumentedMiles");
        networkStatisticsDto.KokaneeSalmonPresumedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "KokaneeSalmonPresumedMiles");
        networkStatisticsDto.KokaneeSalmonGradientAccessibleMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "KokaneeSalmonGradientAccessibleMiles");
        networkStatisticsDto.PinkSalmonDocumentedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "PinkSalmonDocumentedMiles");
        networkStatisticsDto.PinkSalmonPresumedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "PinkSalmonPresumedMiles");
        networkStatisticsDto.PinkSalmonGradientAccessibleMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "PinkSalmonGradientAccessibleMiles");
        networkStatisticsDto.SockeyeSalmonDocumentedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "SockeyeSalmonDocumentedMiles");
        networkStatisticsDto.SockeyeSalmonPresumedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "SockeyeSalmonPresumedMiles");
        networkStatisticsDto.SockeyeSalmonGradientAccessibleMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "SockeyeSalmonGradientAccessibleMiles");
        networkStatisticsDto.SteelheadTroutDocumentedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "SteelheadTroutDocumentedMiles");
        networkStatisticsDto.SteelheadTroutPresumedMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "SteelheadTroutPresumedMiles");
        networkStatisticsDto.SteelheadTroutGradientAccessibleMiles = this.sumPipe.transform(this.reachSearchResult.Reaches, "SteelheadTroutGradientAccessibleMiles");
        networkStatisticsDto.AgricultureAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "AgricultureAcres");
        networkStatisticsDto.AgricultureChapter83Acres = this.sumPipe.transform(this.reachSearchResult.Reaches, "AgricultureChapter83Acres");
        networkStatisticsDto.AgricultureNotLaw81Acres = this.sumPipe.transform(this.reachSearchResult.Reaches, "AgricultureNotLaw81Acres");
        networkStatisticsDto.CommercialAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "CommercialAcres");
        networkStatisticsDto.ForestryForestLandAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "ForestryForestLandAcres");
        networkStatisticsDto.ForestTimberlandDataAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "ForestTimberlandDataAcres");
        networkStatisticsDto.OpenSpaceAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "OpenSpaceAcres");
        networkStatisticsDto.ResidentialAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "ResidentialAcres");
        networkStatisticsDto.ResourceAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "ResourceAcres");
        networkStatisticsDto.TransportationAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "TransportationAcres");
        networkStatisticsDto.UndevelopedLandAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "UndevelopedLandAcres");
        networkStatisticsDto.UndevelopedLands91Acres = this.sumPipe.transform(this.reachSearchResult.Reaches, "UndevelopedLands91Acres");
        networkStatisticsDto.AllLandUseAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "AllLandUseAcres");
        return networkStatisticsDto;
    }

    public createLandCoverChartData() {
        if (this.reachSearchResult) {
            const networkStatistics = this.calculateTotalNetworkStatistics();

            this.landCoverChartData = [];
            this.landCoverChartData.push({
                XValue: "Herbaceous",
                Group: "Riparian Management Zone",
                YValue: networkStatistics.RMZHerbaceousAcres,
                SortOrder: 11,
                HoverValue: networkStatistics.RMZHerbaceousAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Herbaceous",
                Group: "Extent of Observed Water",
                YValue: networkStatistics.EOWHerbaceousAcres,
                SortOrder: 12,
                HoverValue: networkStatistics.EOWHerbaceousAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Herbaceous",
                Group: "Floodplain",
                YValue: networkStatistics.FloodHerbaceousAcres,
                SortOrder: 13,
                HoverValue: networkStatistics.FloodHerbaceousAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Tree",
                Group: "Riparian Management Zone",
                YValue: networkStatistics.RMZTreeCoverAcres,
                SortOrder: 17,
                HoverValue: networkStatistics.RMZTreeCoverAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Tree",
                Group: "Extent of Observed Water",
                YValue: networkStatistics.EOWTreeCoverAcres,
                SortOrder: 18,
                HoverValue: networkStatistics.EOWTreeCoverAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Tree",
                Group: "Floodplain",
                YValue: networkStatistics.FloodTreeCoverAcres,
                SortOrder: 19,
                HoverValue: networkStatistics.FloodTreeCoverAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Shrub",
                Group: "Riparian Management Zone",
                YValue: networkStatistics.RMZShrubAcres,
                SortOrder: 14,
                HoverValue: networkStatistics.RMZShrubAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Shrub",
                Group: "Extent of Observed Water",
                YValue: networkStatistics.EOWShrubAcres,
                SortOrder: 15,
                HoverValue: networkStatistics.EOWShrubAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Shrub",
                Group: "Floodplain",
                YValue: networkStatistics.FloodShrubAcres,
                SortOrder: 16,
                HoverValue: networkStatistics.FloodShrubAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Water",
                Group: "Riparian Management Zone",
                YValue: networkStatistics.RMZWaterAcres,
                SortOrder: 23,
                HoverValue: networkStatistics.RMZWaterAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Water",
                Group: "Extent of Observed Water",
                YValue: networkStatistics.EOWWaterAcres,
                SortOrder: 24,
                HoverValue: networkStatistics.EOWWaterAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Water",
                Group: "Floodplain",
                YValue: networkStatistics.FloodWaterAcres,
                SortOrder: 25,
                HoverValue: networkStatistics.FloodWaterAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "No Vegetation Data",
                Group: "Riparian Management Zone",
                YValue: networkStatistics.RMZNoVegetationDataAcres,
                SortOrder: 26,
                HoverValue: networkStatistics.RMZNoVegetationDataAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "No Vegetation Data",
                Group: "Extent of Observed Water",
                YValue: networkStatistics.EOWNoVegetationDataAcres,
                SortOrder: 27,
                HoverValue: networkStatistics.EOWNoVegetationDataAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "No Vegetation Data",
                Group: "Floodplain",
                YValue: networkStatistics.FloodNoVegetationDataAcres,
                SortOrder: 28,
                HoverValue: networkStatistics.FloodNoVegetationDataAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Non-Vegetated",
                Group: "Riparian Management Zone",
                YValue: networkStatistics.RMZNonVegetatedAcres,
                SortOrder: 20,
                HoverValue: networkStatistics.RMZNonVegetatedAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Non-Vegetated",
                Group: "Extent of Observed Water",
                YValue: networkStatistics.EOWNonVegetatedAcres,
                SortOrder: 21,
                HoverValue: networkStatistics.EOWNonVegetatedAcres / networkStatistics.AllLandCoverAcres,
            });
            this.landCoverChartData.push({
                XValue: "Non-Vegetated",
                Group: "Floodplain",
                YValue: networkStatistics.FloodNonVegetatedAcres,
                SortOrder: 22,
                HoverValue: networkStatistics.FloodNonVegetatedAcres / networkStatistics.AllLandCoverAcres,
            });
        }
    }

    public createLandUseChartData() {
        if (this.reachSearchResult) {
            const networkStatistics = this.calculateTotalNetworkStatistics();

            this.landUseChartData = [];
            this.landUseChartData.push({
                XValue: "Agriculture",
                YValue: networkStatistics.AgricultureAcres,
                HoverValue: networkStatistics.AgricultureAcres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Agriculture (ch 83.34 RCW)",
                YValue: networkStatistics.AgricultureChapter83Acres,
                HoverValue: networkStatistics.AgricultureChapter83Acres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Agriculture (not classified)",
                YValue: networkStatistics.AgricultureNotLaw81Acres,
                HoverValue: networkStatistics.AgricultureNotLaw81Acres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Commercial/Industrial",
                YValue: networkStatistics.CommercialAcres,
                HoverValue: networkStatistics.CommercialAcres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Forestry (ch 84.33 RCW)",
                YValue: networkStatistics.ForestryForestLandAcres,
                HoverValue: networkStatistics.ForestryForestLandAcres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Forestry (ch 84.34 RCW)",
                YValue: networkStatistics.ForestTimberlandDataAcres,
                HoverValue: networkStatistics.ForestTimberlandDataAcres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Open Space (ch 84.34 RCW)",
                YValue: networkStatistics.OpenSpaceAcres,
                HoverValue: networkStatistics.OpenSpaceAcres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Residential",
                YValue: networkStatistics.ResidentialAcres,
                HoverValue: networkStatistics.ResidentialAcres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Resource Production & Extraction",
                YValue: networkStatistics.ResourceAcres,
                HoverValue: networkStatistics.ResourceAcres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Infrastructure",
                YValue: networkStatistics.TransportationAcres,
                HoverValue: networkStatistics.TransportationAcres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Undeveloped Land & Water Areas",
                YValue: networkStatistics.UndevelopedLandAcres,
                HoverValue: networkStatistics.UndevelopedLandAcres / networkStatistics.AllLandUseAcres,
            });
            this.landUseChartData.push({
                XValue: "Undeveloped Lands (91)",
                YValue: networkStatistics.UndevelopedLands91Acres,
                HoverValue: networkStatistics.UndevelopedLands91Acres / networkStatistics.AllLandUseAcres,
            });
        }
    }

    public createSalmonDistributionChartData() {
        if (this.reachSearchResult) {
            const networkStatistics = this.calculateTotalNetworkStatistics();
            this.salmonDistributionChartData = [];
            this.salmonDistributionChartData.push({
                XValue: "Coho Salmon",
                Group: "Documented",
                YValue: networkStatistics.CohoSalmonDocumentedMiles,
                SortOrder: 11,
                HoverValue: networkStatistics.CohoSalmonDocumentedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Coho Salmon",
                Group: "Presumed",
                YValue: networkStatistics.CohoSalmonPresumedMiles,
                SortOrder: 12,
                HoverValue: networkStatistics.CohoSalmonPresumedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Coho Salmon",
                Group: "Gradient Accessible",
                YValue: networkStatistics.CohoSalmonGradientAccessibleMiles,
                SortOrder: 13,
                HoverValue: networkStatistics.CohoSalmonGradientAccessibleMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Chinook Salmon",
                Group: "Documented",
                YValue: networkStatistics.ChinookSalmonDocumentedMiles,
                SortOrder: 14,
                HoverValue: networkStatistics.ChinookSalmonDocumentedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Chinook Salmon",
                Group: "Presumed",
                YValue: networkStatistics.ChinookSalmonPresumedMiles,
                SortOrder: 15,
                HoverValue: networkStatistics.ChinookSalmonPresumedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Chinook Salmon",
                Group: "Gradient Accessible",
                YValue: networkStatistics.ChinookSalmonGradientAccessibleMiles,
                SortOrder: 16,
                HoverValue: networkStatistics.ChinookSalmonGradientAccessibleMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Pink Salmon",
                Group: "Documented",
                YValue: networkStatistics.PinkSalmonDocumentedMiles,
                SortOrder: 17,
                HoverValue: networkStatistics.PinkSalmonDocumentedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Pink Salmon",
                Group: "Presumed",
                YValue: networkStatistics.PinkSalmonPresumedMiles,
                SortOrder: 18,
                HoverValue: networkStatistics.PinkSalmonPresumedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Pink Salmon",
                Group: "Gradient Accessible",
                YValue: networkStatistics.PinkSalmonGradientAccessibleMiles,
                SortOrder: 19,
                HoverValue: networkStatistics.PinkSalmonGradientAccessibleMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Chum Salmon",
                Group: "Documented",
                YValue: networkStatistics.ChumSalmonDocumentedMiles,
                SortOrder: 20,
                HoverValue: networkStatistics.ChumSalmonDocumentedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Chum Salmon",
                Group: "Presumed",
                YValue: networkStatistics.ChumSalmonPresumedMiles,
                SortOrder: 21,
                HoverValue: networkStatistics.ChumSalmonPresumedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Chum Salmon",
                Group: "Gradient Accessible",
                YValue: networkStatistics.ChumSalmonGradientAccessibleMiles,
                SortOrder: 22,
                HoverValue: networkStatistics.ChumSalmonGradientAccessibleMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Steelhead Trout",
                Group: "Documented",
                YValue: networkStatistics.SteelheadTroutDocumentedMiles,
                SortOrder: 23,
                HoverValue: networkStatistics.SteelheadTroutDocumentedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Steelhead Trout",
                Group: "Presumed",
                YValue: networkStatistics.SteelheadTroutPresumedMiles,
                SortOrder: 24,
                HoverValue: networkStatistics.SteelheadTroutPresumedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Steelhead Trout",
                Group: "Gradient Accessible",
                YValue: networkStatistics.SteelheadTroutGradientAccessibleMiles,
                SortOrder: 25,
                HoverValue: networkStatistics.SteelheadTroutGradientAccessibleMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Sockeye Salmon",
                Group: "Documented",
                YValue: networkStatistics.SockeyeSalmonDocumentedMiles,
                SortOrder: 26,
                HoverValue: networkStatistics.SockeyeSalmonDocumentedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Sockeye Salmon",
                Group: "Presumed",
                YValue: networkStatistics.SockeyeSalmonPresumedMiles,
                SortOrder: 27,
                HoverValue: networkStatistics.SockeyeSalmonPresumedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Sockeye Salmon",
                Group: "Gradient Accessible",
                YValue: networkStatistics.SockeyeSalmonGradientAccessibleMiles,
                SortOrder: 28,
                HoverValue: networkStatistics.SockeyeSalmonGradientAccessibleMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Kokanee Salmon",
                Group: "Documented",
                YValue: networkStatistics.KokaneeSalmonDocumentedMiles,
                SortOrder: 29,
                HoverValue: networkStatistics.KokaneeSalmonDocumentedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Kokanee Salmon",
                Group: "Presumed",
                YValue: networkStatistics.KokaneeSalmonPresumedMiles,
                SortOrder: 30,
                HoverValue: networkStatistics.KokaneeSalmonPresumedMiles / networkStatistics.MilesOfStream,
            });
            this.salmonDistributionChartData.push({
                XValue: "Kokanee Salmon",
                Group: "Gradient Accessible",
                YValue: networkStatistics.KokaneeSalmonGradientAccessibleMiles,
                SortOrder: 31,
                HoverValue: networkStatistics.KokaneeSalmonGradientAccessibleMiles / networkStatistics.MilesOfStream,
            });
        }
    }

    public createZoneAreaChartData() {
        if (this.reachSearchResult) {
            const totalAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "ReachAcres");
            const eowAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "EOWAcres");
            const rmzAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "RMZAcres");
            const floodAcres = this.sumPipe.transform(this.reachSearchResult.Reaches, "FloodAcres");

            this.zoneAreaChartData = [];
            this.zoneAreaChartData.push({
                XValue: "Riparian Management Zone",
                YValue: rmzAcres,
                HoverValue: rmzAcres / totalAcres,
            });
            this.zoneAreaChartData.push({
                XValue: "Extent of Observed Water",
                YValue: eowAcres,
                HoverValue: eowAcres / totalAcres,
            });
            this.zoneAreaChartData.push({
                XValue: "Floodplain",
                YValue: floodAcres,
                SortOrder: 13,
                HoverValue: floodAcres / totalAcres,
            });
        }
    }
}

export interface ReachExplorerEntityDto {
    EntityID: number;
    EntityName: string;
    TotalAcres?: number;
    HasData?: boolean;
    RiparianAcres?: number;
    StreamMiles?: number;
    EntityIDAndName: string;
}
